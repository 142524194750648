import {
  GET_INSPECTION_RECORDS,
  SET_ACTIVE_VEHICLE,
  SET_VEHICLES,
  TOGGLE_LOADING,
  SET_RECORD_INDEX,
  TOGGLE_RECORDS_LOADING,
  FILTER_VEHICLES,
  ADD_INSPECTION_RECORD,
  UPDATE_INSPECTION_DETAILS,
} from "../actions/inspection.action";

const initialState = {
  activeVehicle: null,
  isLoading: false,
  isLoadingRecords: false,
  inspectionRecords: {},
  inspectionVehicles: [],
  recordIndex: null,
  activeFilter: "all",
};

const InspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return { ...state, isLoading: action.value };
    case GET_INSPECTION_RECORDS:
      return {
        ...state,
        inspectionRecords: {
          ...state.inspectionRecords,
          [action.vehicle]: action.value,
        },
      };
    case SET_ACTIVE_VEHICLE:
      return {
        ...state,
        activeVehicle: action.value,
      };
    case SET_VEHICLES:
      return {
        ...state,
        inspectionVehicles: action.value,
      };
    case FILTER_VEHICLES:
      return { ...state, activeFilter: action.value };

    case TOGGLE_RECORDS_LOADING:
      return { ...state, isLoadingRecords: action.value };
    case UPDATE_INSPECTION_DETAILS:
      let inspectionVehiclesClone = state.inspectionVehicles;
      for (const group of inspectionVehiclesClone) {
        for (const vehicle of group["vehicles"]) {
          if (vehicle["id"] == action.vehicleId) {
            vehicle["inspectionDetails"] = action.inspectionDetails;
          }
        }
      }
      return {
        ...state,
        inspectionVehicles: inspectionVehiclesClone,
      };
    case SET_RECORD_INDEX:
      return {
        ...state,
        recordIndex: action.value,
      };
    case ADD_INSPECTION_RECORD:
      let records = [];
      records = [...state.inspectionRecords[action.vehicleId]];
      return {
        ...state,
        inspectionRecords: {
          ...state.inspectionRecords,
          [action.vehicleId]: records,
        },
      };
    default:
      return state;
  }
};

export default InspectionReducer;
